export default [
  					
  // { name: '首頁 ', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '國家公園', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: -40 },
  { name: '青春泉源', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: 0 },
  { name: '名人聚落', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 20 },
  { name: '稀珍價值', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 20 },
  { name: '渡假別墅', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: -70 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]
