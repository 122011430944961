import { render, staticRenderFns } from "./Section1.vue?vue&type=template&id=f63c5446&scoped=true&"
import script from "./Section1.vue?vue&type=script&lang=js&"
export * from "./Section1.vue?vue&type=script&lang=js&"
import style0 from "./Section1.vue?vue&type=style&index=0&id=f63c5446&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f63c5446",
  null
  
)

export default component.exports